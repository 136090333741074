.destination-port {
  display: flex;
  background-color: var(--color-1);
  color: white;
  flex-shrink: 0;
  justify-content: flex-start;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  min-height: 70px;
  padding: 4px 0;

  @media screen and (min-width: 500px) {
    padding: 4px 10px;
  }
  &--end {
    justify-content: flex-end;
  }
}

.counter {
  color: var(--color-4);
  font-size: calc(min(7vw, 60px));
  font-weight: bold;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 0.9;
  margin: 0 8px;

  sup {
    font-size: calc(min(2vw, 20px));
    font-weight: normal;
    text-transform: lowercase;
    @media screen and (max-width: 375px) {
      display: none;
    }
  }
}

.destination-port__loading {
  height: 1em;
  padding: 0 1em;
}

.card {
  background-color: #3d72b3;
  padding: 0rem 0.7rem;
  border-radius: 0.4rem;
  margin: 10px 5px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: calc(min(5vw, 20px));
  // word-break: break-all;

  box-sizing: border-box;

  @media screen and (max-width: 375px) {
    padding: 0rem 0.2rem;
  }

  &--blank {
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
}
.card--end {
  padding: 0rem 0.7rem;
  flex-grow: 2;
  justify-content: flex-end;
  display: flex;
}

.card--time {
  @media screen and (max-width: 665px) {
    flex-direction: column;
    align-items: baseline;
  }
  padding: 0.2rem 0.7rem;
  .card--time__info {
    color: var(--color-4);
    font-weight: 100;
    font-size: calc(min(3.9vw, 18px));
    font-size: 1.2em;
  }
}

.line {
  height: 2px;
  width: 100vw;
  background-color: rgb(33, 87, 152);
}

.times-container {
  display: flex;
  overflow: scroll;
  /* for Firefox */
  // min-height: 0;
}

.dep-container {
  background-color: var(--color-2);
}

.departure {
  position: relative;
  background-color: var(--color-2);
  display: flex;
  padding: 1em 0;
  @media screen and (min-width: 400px) {
    padding: 1em 10px;
  }
  flex-shrink: 0;
  align-items: stretch;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  z-index: 1;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
  max-height: 60px;
  transition: max-height 0.5s;

  .departure-link {
    margin-bottom: 50px;
    transition: margin-bottom 0.2s;
    transition-delay: 0.3s;
  }
}

.expand {
  max-height: 600px;
  transition: max-height 0.5s;
  .departure-link {
    margin-bottom: 5px;
    transition: margin-bottom 0.2s;
  }
}

.toggle {
  width: 100%;
  height: 0px;
  position: relative;
  $size: 60px;
  &--btn {
    background-color: var(--color-2);
    z-index: 3;
    width: $size;
    height: $size / 2;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    border-bottom-left-radius: $size * 2;
    border-bottom-right-radius: $size * 2;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  }
  @media screen and (min-width: 1000px) {
    display: none;
  }
}

.departure-link {
  margin: 0.2em;
}

.top {
  transition: all 0.3s;
  padding-top: 10px !important;
}

@import './utils/variables';

.container {
  /* give the outermost container a predefined size */
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: var(--color-1);
  background-color: var(--color-3);
}

.section {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  height: 100vh;

  /* for Firefox */
  min-height: 0;
}

a {
  color: var(--color-3);
  text-decoration: none;
  cursor: pointer;
}

.ferry-times {
  text-align: center;
  flex: 1;
  /* for Firefox */
  // min-height: 0;
  font-size: calc(min(4vw, 1.3em));

  color: rgb(63, 63, 63);
  padding: 0.3rem 0;
  background-color: rgb(230, 230, 230);
  &__title {
    text-transform: uppercase;
    color: rgb(114, 108, 108);
    font-weight: 600;
  }
  &__time {
    font-family: 'Source Sans Pro', sans-serif;
    &:nth-child(even) {
      background-color: rgb(221, 221, 221);
    }
  }
  &__count-down {
    font-weight: 100;
    color: rgb(63, 63, 63);
    padding-left: 5px;
  }
}
